.landing-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.segment-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers child horizontally */
  align-items: center;    /* Centers child vertically */
}

.about-intro-mobile {
  display: none;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .landing-container {
    align-items: center; /* Center align items */
  }
  
  .about-intro-mobile {
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #021858 0%, #1F45B8 98.46%);
    width: 100%;
  }

  .about-intro-mobile-content {
    margin-left: 10%;
    margin-top: 10%;
    width: 70%;
  }

  .aimc-h {
    color: white;
    font-size: 24px;
    font-weight: 700;
  }

  .aimc {
    color: white;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 25%;
  }

  .aimc-ki-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: white;
    font-size: 20px;
  }

  .aimc-ki-content {
    align-items: center;
    margin-bottom: 7%;
  }

  .arrow-white {
    width: 14px;
    height: 14px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
