.intro-contact-container {
  background: linear-gradient(90deg, #E8EEFF 0%, #BFC9EA 98.46%);
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.intro-contact-text {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}

.intro-contact-button {
  height: 56px;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-top: 4%;
  margin-right: 3%;
}

.intro-contact-ci {
  color: #021858;
  font-weight: 600;
  font-size: 15px;
  margin-right: 5%;
}

.arrow {
  width: 24px;
  height: 24px;
}
  
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .intro-contact-container {
    display: none;
  }
}
  