/* Default styles for large screens (row layout) */
.intro-item {
  background-image: url('../../../../images/landing-background-1.png');
  height: 1000px;
  background-size: cover; /* Cover the entire area of the div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  width: 100%;
}

.intro-item-content {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-left: 10%;
  border: 2px solid greenblack;
}

.vz {
  font-weight: 700;
  font-size: 88px;
  color: #3265FC;
}

.np {
  font-weight: 700;
  font-size: 88px;
  color: #5AC06D;
}

.psn {
  width: 80%;
  margin-top: 48px;
  font-size: 24px;
  color: #0D503B;
}

.b {
  height: 77px;
  width: 280px;
  background-color: #3265FC;
  margin-top: 50px;
  border-radius: 24px;
  border-color: transparent;
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 60px;
}

.numbers {
  width: 80%;
  display: flex;
  font-size: 64px;
  font-weight: 500;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .intro-item {
    width: 100%;
    height: 550px;
  }
  
  .intro-item-content {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-left: 0;
    width: 95%;
    padding-left: 10px;
  }
  
  .vz {
    font-size: 48px;
    width: 90%;
  }
  
  .np {
    font-size: 48px;
    width: 90%;
  }
  
  .psn {
    font-size: 16px;
    width: 100%;
    width: 90%;
  }
  
  .b {
    font-size: 16px;
    height: 50px;
    margin-bottom: 30px;
  }
  
  .numbers {
    font-size: 28px;
    align-items: flex-start;
    height: 30%;
  } 
}
  