.service-intro-container {
  background: linear-gradient(0deg, #FFFFFF 25.29%, rgba(255, 255, 255, 0) 78.71%);
  background-image: url('../../../../images/section-3-background.png');
  background-size: cover; /* Cover the entire area of the div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  width: 100%;
  align-items: center;
}

.service-intro-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 118px;
  margin-left: 10%;
}

.si-usuge {
  font-size: 64px;
  font-weight: 700;
  color: #3265FC;
  margin-bottom: 60px;
}

.service-list {
  font-size: 24px;
  color: #0D503B;
  display: inline-grid;
  grid-template-columns: auto auto auto;
}

.service-list-content {
  display: flex;
  justify-content: space-between;
}

.service-details-button {
  height: 56px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin-top: 60px;
  margin-bottom: 135px;
}

.details-text {
  color: #3265FC;
  font-weight: 600;
  font-size: 15px;
}

.arrow-sd {
  width: 14px;
  height: 14px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .service-list {
    grid-template-columns: auto;
  }

  .si-usuge {
    font-size: 32px;
  }

  .service-intro-content {
    margin-top: 20%;
  }

  .service-details-button {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
    