.header-container {
  width: 80%;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 10%;
  padding-right: 10%;
  height: 122px;
}

.modal {
  display: none;
}

.image-button {
  display: none;
}

.header-item-logo {
  cursor: pointer;
  object-fit: contain;
  width: 20%;
  height: 55px;
}

.header-item-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-text {
  cursor: pointer;
  font-weight: 400;
  transition: color 0.3s ease;
  margin-right: 3vh;
}

.menu-text:hover {
  color: #5AC06D;
}

.blue-text {
  color: blue;
}

.base-text {
  color: #0D503B;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .header-container {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    height: 64px;
  }

  .links-m {
    display: flex;
    flex-direction: column;
  }

  .header-item-logo-modal {
    height: 4%;
    cursor: pointer;
  }

  .header-item-menu {
   display: none;
  }

  .image-button {
    display: flex;
    background-color: transparent; /* Transparent background */
    border: 2px solid transparent; /* Transparent border */
  }

  .button-icon {
    width: 32px;
    height: 32px;
  }

  .image-button:hover {
    background-color: #f0f8ff;
    color: #0056b3;
    box-shadow: 0px 4px 24px 0px #00000040;
  }

  .image-button:active {
    background-color: #e6f2ff;
    color: #004085;
  }

  .modal {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1); /* Providna bela boja */
    box-shadow: -4px 0px 24px 0px #30131340;
    z-index: 4;
  }

  .modal-content {
    padding: 20px;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 34px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .nav-routes {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .modal-text {
    margin: 10px 0;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    color: #0D503B;
  }
}
