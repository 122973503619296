.team-content-container {
  background: #FFFFFF80;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  border-radius: 16px;
}

.team-content-container:hover {
  box-shadow: 0px 4px 24px 0px #00000040; /* Apply shadow on hover */
}

.team-content-image {
  width: 240px;
  height: 240px;
  border-radius: 40px;
  margin: 24px 16px 24px 24px;
}

.team-content-div {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 24px 24px 24px 0px;
}

.team-content-div-header {
  display: flex;
  flex-direction: column;
  color: #021858;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 5px;
}

.team-content-div-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 10px;
}

.team-content-div-profession {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 25px;
}

.profession-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.profession-span {
  color: #021858;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}


/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .team-content-container {
    display: flex;
    flex-direction: column;
  }

  .team-content-image {
    width: 148px;
    height: 148px;
    margin: 24px 16px 16px 24px;
  }

  .team-content-div {
    margin: 0px 16px 5px 16px;
  }

  .team-content-div-header {
    flex-direction: column;
  }

  .profession-img {
    width: 32px;
    height: 32px;
  }
}