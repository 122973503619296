/* Default styles for large screens (row layout) */
.footer-container {
  width: 80%;
  background: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
  padding-bottom: 3%;
}

.footer-contents {
  display: flex;
  height: 130px;
  flex-direction: column;
  justify-content: space-between;
}

.footer-item-logo {
  cursor: pointer;
  object-fit: contain;
  width: 20%;
  height: 55px;
}

.footer-contents-text {
  width: 85%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.quick-links {
  height: 150px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quick-link-text:hover {
  color: #5AC06D;
  cursor: pointer;
}

.links {
  width: 10%;
  height: 150px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .footer-container {
    /* height: 545px; */
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
    width: 94%;
    justify-content: flex-start;
    padding-bottom: 3%;
  }

  .links {
    width: 50%;
    height: 100px;
    margin-top: 3%;
  }

  .quick-links {
    margin-top: 5%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-contents {
    width: 332px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .footer-item-logo {
    width: 150px;
    cursor: pointer;
    object-fit: contain;
  }
  
  .footer-contents-text {
    width: 332px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
  