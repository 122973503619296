.about-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: radial-gradient(93.67% 173.1% at 50% 49.95%, #FFFFFF 0%, rgba(212, 245, 218, 0.5) 100%);
}

.about-content{
  margin: 144px 200px 144px 200px;
  width: 80%;
  display: flex;
}

.imgdoctors {
  width: 40%;
  height: 70%;
  margin-top: 100px;
  margin-bottom: 100px;
  object-fit: contain;
}

.about-text {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
}

.ksm {
  color: #5AC06D;
  height: 63px;
  font-weight: 700;
  font-size: 64px;
  margin-bottom: 5%;
}

.sc {
  font-size: 24px;
  color: #0D503B;
  margin-top: 5%;
}

.about-section {
  display: flex;
  flex-direction: column;
}

.section {
  font-size: 24px;
  color: #0D503B;
  display: inline-grid;
  grid-template-columns: auto auto;
}
.section-content {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.img-elipse {
  width: 16px;
  height: 16px;
  margin-top: 2%;
  margin-right: 10px;
}

.about-end {
  width: 100%;
  margin-top: 10%;
  font-size: 24px;
  color: #0D503B;
}
  
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .about-content {
    display: flex;
    flex-direction: column;
    margin: 1px 2px 1px 2px;
    /* justify-content: center; */
  }

  .imgdoctors {
    width: 200px;
    height: 200px; 
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 15%;
  }

  .about-text {
    margin-left: 13%;
    width: 250px;
    margin-bottom: 5%;
  }

  .ksm {
    font-size: 40px;
  }

  .section {
    grid-template-columns: auto;
    margin-top: 2%;
  }

  .img-elipse {
    width: 10px;
    height: 10px;
    margin-right: 3%;
  }

  .sc {
    margin-top: 10%;
    font-size: 18px;
  }
  
  .scs {
    font-size: 18px;
  }

  .about-end {
    font-size: 18px;
  }
}
  