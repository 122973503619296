.service-container {
  background-image: url('../../images/bst-background.png');
  background-size: cover; /* Cover the entire area of the div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  backdrop-filter: blur(148px);
  display: flex;
  flex-direction: column;
}

.service-container-header {
  color: #3265FC;
  font-weight: 700;
  font-size: 48px;
  margin-top: 5%;
  margin-left: 13%;
  margin-bottom: 4%;
}

.service-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  background: #FFFFFF80;
  gap: 20px;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 3%;
}

/* Ensure the last item spans two columns if it's the only one in the last row */
.service-grid-container > div:last-child:nth-child(odd) {
  grid-column: span 2;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .service-grid-container {
    display: grid;
    grid-template-columns: auto;
    margin-left: 2%;
    margin-right: 2%;
  }
  .service-grid-container > div:last-child:nth-child(odd) {
    grid-column: span 1;
  }

  .service-container-header {
    margin-top: 70px;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
    font-size: 36px;
  }
}
      