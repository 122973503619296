.contact-intro-container{
  background-image: url('../../../../images/contact-intro-web.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 7%;
  margin-bottom: 7%;
  align-items: center;
  margin-left: 20%;
}

.pr {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  margin-top: 8%;
}
  
.mdn {
  width: 70%;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.contact-button {
  width: Hug (280px)px;
  height: Hug (77px)px;
  top: 143px;
  left: 294.42px;
  padding: 24px 40px 24px 40px;
  cursor: pointer;
  border-radius: 16px;
  color: #3265FC;
  background: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  border-color: transparent;
  margin-top: 24px;
  margin-bottom: 50px;
}
  
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .contact-intro-container{
    margin-left: 12px;
    margin-right: 12px;
    width: 350px;
    margin-top: 15%;
    margin-bottom: 15%;
    background-image: url('../../../../images/contact-intro-mobile.png');
    background-size: contain;
    background-position: top; 
    background-repeat: no-repeat; 
  }
  
  .pr {
    width: 75%;
    margin-bottom: 11px;
  }
  
  .contact-button {
    width: 215px;
    height: 50px;
    font-size: 16px;
    align-items: center;
    padding: 0px;
  }
}
      