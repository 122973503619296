.contact-content-container {
  background: linear-gradient(90deg, #021858 0%, #1F45B8 98.46%);
  height: 530px;
  width: 100%;
}

.contact-values {
  display: flex;
  flex-direction: column;
  width: 66%;
  justify-content: center;
  padding-left: 17%;
}

.contact-values-header {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  margin-bottom: 12px;
}

.contact-values-text {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  margin-bottom: 20px;
}

.contact-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
  padding: 20px 0px;
}

.contact-grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-grid-item-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;
}

.contact-grid-item-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-grid-item-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.contact-grid-item-values {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .contact-content-container {
    height: 800px;
  }

  .contact-grid {
    display: flex;
    flex-direction: column;  
  }

  .contact-values {
    padding-left: 3%;
  }
}

