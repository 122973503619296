.image-content-container {
  width: 70%;
  height: 60%;
  position: absolute;
  background-image: url('../../../../images/career-img.png');
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 12%;
}

.image-img {
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 1100px) {
  .image-content-container {
    background-image: url('../../../../images/career-img-mobile.png');
    width: 80%;
    height: 55%;
    object-fit: contain;
    background-size:auto;
    margin-top: 30%;
  }
}
