.career-intro-container{
  background: linear-gradient(94.77deg, #122D80 0%, #1F45B8 50.57%, #95FFA8 100.94%);
  width: 100%;
  height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rsn {
  font-size: 72px;
  font-weight: 700;
  line-height: 87.14px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  margin-top: 140px;
}

.pop {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.career-button {
  width: 170px;
  height: 77px;
  cursor: pointer;
  border-radius: 24px;
  color: white;
  background: #3265FC;
  font-size: 24px;
  font-weight: 600;
  border-color: transparent;
  margin-top: 24px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .rsn {
    font-size: 48px;
  }

  .career-button {
    width: 140px;
    height: 60px;
    font-size: 16px;
    margin-top: 25%;
  }

  .pop {
    margin-top: 8%;
  }
}
    