.white-content-container {
  background: white;
  height: 530px;
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .white-content-container {
    height: 420px;
  }
}
  