.map-content-container {
  width: 70%;
  height: 45%;
  position: absolute;
  background-image: url('../../../../images/map_web.png');
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 15%;
}

.map-img {
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .map-content-container {
    background-image: url('../../../../images/map_mobile.png');
    margin-bottom: 2%;
    width: 75%;
  }
}
