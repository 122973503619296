
.team-grid-item {
  position: relative;
  height: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  width: 260px;
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #021858 0%, rgba(2, 24, 88, 0.1) 100%);
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-decoration: underline;
  font-size: 16px;
  padding-bottom: 5%;
}

.hover-overlay:hover {
  opacity: 1;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .team-grid-item {
    width: 150px;
    height: 94px;
  }
}
      