.career-content-container {
  background: linear-gradient(90deg, #021858 0%, #1F45B8 98.46%);
  height: 300px;
  width: 100%;
}

.career-values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 12% 0 12%;
}

.career-values-header {
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: white;
}

.career-values-text {
  text-align: left;
  color: white;
  font-weight: 400;
  font-size: 18px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .career-content-container {
    height: 450px;
  }

  .career-values {
    padding: 2% 7% 0 7%;
  }
  
  .career-values-header {
    font-size: 28px;
  } 

  .career-values-text {
    width: 80%;
  }
}
