.career-image-content-container {
  background-image: url('../../../../images/landing-background-1.png');
  height: 550px;
  width: 100%;
}

.career-image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 12% 0 12%;
}

.career-image-content-header {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -2%;
  text-align: center;
  color: #3265FC;
}
 
.career-image-content-value {
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0%;
  text-align: center;
}

.career-image-content-button-div {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.career-image-content-phone-button {
  background: linear-gradient(90deg, #021858 0%, #1F45B8 98.46%);
  width: 272px;
  height: 60px;
  border-radius: 210px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-color: transparent;
  margin-top: 24px;
  display: flex;
  align-items: center;  /* Vertikalno centriranje */
  justify-content: center; /* Horizontalno centriranje (ako je potrebno) */
}

.container img {
  width: 20px; /* Prilagodite veličinu ikone */
  height: 20px;
}

.career-item-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;
}

.career-image-content-mail-button {
  background: linear-gradient(90deg, #021858 0%, #1F45B8 98.46%);
  width: 350px;
  height: 60px;
  border-radius: 210px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-color: transparent;
  margin-top: 24px;
  display: flex;
  align-items: center;  /* Vertikalno centriranje */
  justify-content: center; /* Horizontalno centriranje (ako je potrebno) */
}
  
/* Media Query for Mobile Devices */
@media (max-width: 1100px) {
  .career-image-content-container {
    height: 750px;
  }
  .career-image-content-button-div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .career-image-content {
    padding: 2% 10% 0 10%;
    width: 80%;
  }
  
  .career-image-content-header {
    font-size: 28px;
  }
  
  .career-image-content-phone-button {
    width: 230px;
  }
    
  .career-item-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: contain;
  }
}
    