.white-career-content-container {
  background: white;
  height: 370px;
  width: 100%;
}

.career-content-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 3% 12% 0 0;
}

.career-content-div-header {
  color: #3265FC;
  font-weight: 600;
  font-size: 36px;
  text-align: right;
}

.career-content-div-values {
  color: #030919;
  font-weight: 400;
  font-size: 18px;
  text-align: right;
}

.career-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
}

.career-list li {
  color: #030919;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .white-career-content-container {
    height: 450px;
    padding-bottom: 5%;
  }

  .career-content-div {
    padding-left: 7%;
    width: 80%;
  }
  
  .career-content-div-header {
    font-size: 28px;
  }
  
  .career-content-div-values {
    font-size: 18px;
  }
}
  