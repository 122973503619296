.team-container {
  background-image: url('../../images/bst-background.png');
  display: flex;
  flex-direction: column;
  width: 100%;
}

.team-header {
  color: #3265FC;
  font-weight: 700;
  font-size: 48px;
  margin-top: 5%;
  margin-left: 13%;
  margin-bottom: 4%;
}

.team-list {
  margin-left: 10%;
  margin-right: 10%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .team-header {
    margin-top: 70px;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 36px;
    margin-bottom: 10%;
  }

  .team-list {
    margin-left: 12px;
    margin-right: 12px;
  }
}