.main-in {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}

.label-in {
  font-size: 18px;
  color: #0D503B;
  text-align: center; 
  margin-top: 10%;
}

.value-in {
  font-size: 64px;
  font-weight: 600;
  color: #5AC06D;
  width: 4ch; /* 4 karaktera, dovoljno za brojeve poput 1000 */
  text-align: center;
  display: inline-block; /* Sprečava promene veličine */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .value-in {
    font-size: 38px;
    width: 4ch; /* 4 karaktera, dovoljno za brojeve poput 1000 */
    text-align: center;
    display: inline-block; /* Sprečava promene veličine */
  }

  .label-in {
    font-size: 10px;
  }

  .main-in {
    margin-right: 2%;
  }
}
  