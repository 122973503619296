.team-intro-container {
  background: #031959;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tema-text {
  color: white;
  font-size: 64px;
  font-weight: 700;
  margin-top: 96px;
}

.team-details-button {
  cursor: pointer;
  border-radius: 24px;
  color: white;
  background: #3265FC;
  width: 190px;
  height: 77px;
  font-size: 24px;
  font-weight: 600;
  border-color: transparent;
  margin-top: 24px;
}

/* .team-grid {
  margin-top: 3%;
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
} */
.team-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 kolona */
  margin-top: 3%;
  margin-bottom: 5%;
}

.team-grid > *:nth-child(11) { /* Prva slika */
  grid-column: 2; 
}

.team-grid > *:nth-child(12) { /* Druga slika */
  grid-column: 3; 
}

.team-grid > *:nth-child(13) { /* Treća slika */
  grid-column: 4; 
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .tema-text {
    font-size: 40px;
  }

  .team-grid {
    grid-template-columns: auto auto;
  }

  .team-details-button {
    width: 150px;
    height: 50px;
    font-size: 16px;
  }

  .team-grid > *:nth-child(11) { /* Treća slika */
    grid-column: 1; 
  }
  .team-grid > *:nth-child(12) { /* Treća slika */
    grid-column: 2; 
  }
  .team-grid > *:nth-child(13) { /* Treća slika */
    grid-column: 1; 
  }
}

      