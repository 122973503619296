.main-silc {
  display: flex;
  display: flex;
  flex-direction: column;
  padding: 7%;
}

.icon-silc {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}

.label-silc {
  color: #021858;
  font-weight: 700;
  margin-bottom:16px;
  font-size: 24px;
}

.value-silc {
  color: #021858;
  font-size: 18px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .main-silc {
    justify-content: center;
    align-items: center;
  }

  .value-silc {
    text-align: center;
  }
}
      