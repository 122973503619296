.service-grid-item {
  background: #FFFFFF80;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-grid-item:hover {
  box-shadow: 0px 4px 24px 0px #00000040; /* Apply shadow on hover */
}

.sgc-footer-img {
  width: 64px;
  height: 64px;
  border-radius: 40px;
  margin-right: 10px;
}

.heading-img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.sgc-footer-profession {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #030919;
}

.grid-title {
  font-size: 24px;
  color: #021858;
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-description {
  font-size: 18px;
  margin: 10px 0;
  color: #030919;
}

.grid-doctor {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: 0.9em;
  color: #333;
  font-style: italic;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .sgc-footer-profession {
    display: flex;
    flex-direction: column;
  }
}
      