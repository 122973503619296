.main-icc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15%;
}

.icon-icc {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.label-icc {
  color: #021858;
  font-weight: 700;
}

.value-icc {
  font-weight: 500;
  color: #021858;
  margin-top: 10px;
}

.text-icc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
    