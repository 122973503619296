.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .contact-container {
    justify-content: flex-end;
  }
}
